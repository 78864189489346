import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=af808cc6&scoped=true&"
import script from "./_slug.vue?vue&type=script&lang=js&"
export * from "./_slug.vue?vue&type=script&lang=js&"
import style0 from "./_slug.vue?vue&type=style&index=0&id=af808cc6&prod&lang=scss&scoped=true&"
import style1 from "./_slug.vue?vue&type=style&index=1&id=af808cc6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af808cc6",
  null
  
)

export default component.exports